<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-title>Pacientes por entidad</b-card-title>
                    <div
                        class="container overflow-auto"
                        style="height: calc(100vh - 70vh)"
                    >
                        <div
                            v-for="(barra, index) in pacientesEntidad"
                            :key="index"
                            class="pb-1"
                        >
                            <p>{{ barra.nombre }}</p>
                            <b-progress
                                :value="barra.value"
                                max="100"
                                class="progress-bar-secondary"
                                variant="secondary"
                                show-progress
                            />
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col>
                <b-card>
                    <b-card-title>Asistencias por entidad</b-card-title>
                    <div
                        class="container overflow-auto"
                        style="height: calc(100vh - 70vh)"
                    >
                        <div
                            v-for="(barra, index) in pacientesEntidad"
                            :key="index"
                            class="pb-1"
                        >
                            <p>{{ barra.nombre }}</p>
                            <b-progress
                                :max="
                                    barra.realizadas +
                                    barra.reprogramadas +
                                    barra.canceladas
                                "
                                class="progress-bar-secondary"
                                variant="secondary"
                                show-progress
                            >
                                <b-progress-bar
                                    variant="success"
                                    :value="barra.realizadas"
                                    show-progress
                                    v-b-tooltip.hover.top="'Realizadas'"
                                />
                                <b-progress-bar
                                    variant="warning"
                                    :value="barra.reprogramadas"
                                    show-progress
                                    v-b-tooltip.hover.top="'Reprogramadas'"
                                />
                                <b-progress-bar
                                    variant="danger"
                                    :value="barra.canceladas"
                                    show-progress
                                    v-b-tooltip.hover.top="'Canceladas'"
                                />
                            </b-progress>
                        </div>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-title
                        >Cantidad de pacientes activos por mes</b-card-title
                    >
                    <div class="row justify-content-end mb-1">
                        <div class="col-auto text-right">
                            <small>Seleccione la entidad a analizar</small>
                            <v-select
                                :options="['Todos', 'Clinica 1']"
                                value="Todos"
                            />
                        </div>
                    </div>
                    <vue-apex-charts
                        type="line"
                        height="240"
                        :options="salesLine.chartOptions"
                        :series="salesLine.series"
                    />
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-title
                        >Cantidad de asitencias realizadas por mes</b-card-title
                    >
                    <div class="row justify-content-end mb-1">
                        <div class="col-auto text-right">
                            <small>Seleccione la entidad a analizar</small>
                            <v-select
                                :options="['Todos', 'Clinica 1']"
                                value="Todos"
                            />
                        </div>
                    </div>
                    <vue-apex-charts
                        type="line"
                        height="240"
                        :options="salesLine.chartOptions"
                        :series="salesLine.series"
                    />
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { ref, onMounted } from "@vue/composition-api";
import { $themeColors } from "@themeConfig";

export default {
    setup(props) {
        const pacientesEntidad = ref([
            {
                nombre: "Intituto San Camilo",
                value: 40,
                realizadas: 130,
                canceladas: 23,
                reprogramadas: 30,
            },
            {
                nombre: "Clínica psiquiatrica isnor",
                value: 80,
                realizadas: 150,
                canceladas: 23,
                reprogramadas: 10,
            },
            {
                nombre: "Clínica bucaramanga",
                value: 20,
                realizadas: 140,
                canceladas: 32,
                reprogramadas: 20,
            },
            {
                nombre: "Hospital del norte",
                value: 10,
                realizadas: 100,
                canceladas: 20,
                reprogramadas: 30,
            },
            {
                nombre: "Hospital del norte",
                value: 10,
                realizadas: 100,
                canceladas: 20,
                reprogramadas: 30,
            },
            {
                nombre: "Hospital del norte",
                value: 10,
                realizadas: 100,
                canceladas: 20,
                reprogramadas: 30,
            },
            {
                nombre: "Hospital del norte",
                value: 10,
                realizadas: 100,
                canceladas: 20,
                reprogramadas: 30,
            },
        ]);

        const salesLine = ref({
            series: [
                {
                    name: "Sales",
                    data: [
                        140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240,
                        295,
                    ],
                },
            ],
            chartOptions: {
                chart: {
                    toolbar: { show: false },
                    zoom: { enabled: false },
                    type: "line",
                    dropShadow: {
                        enabled: true,
                        top: 18,
                        left: 2,
                        blur: 5,
                        opacity: 0.2,
                    },
                    offsetX: -10,
                },
                stroke: {
                    curve: "smooth",
                    width: 4,
                },
                grid: {
                    borderColor: "#ebe9f1",
                    padding: {
                        top: -20,
                        bottom: 5,
                        left: 20,
                    },
                },
                legend: {
                    show: false,
                },
                colors: ["#00cfe8"],
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        inverseColors: false,
                        gradientToColors: [$themeColors.primary],
                        shadeIntensity: 1,
                        type: "horizontal",
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100, 100, 100],
                    },
                },
                markers: {
                    size: 0,
                    hover: {
                        size: 5,
                    },
                },
                xaxis: {
                    labels: {
                        offsetY: 5,
                        style: {
                            colors: "#b9b9c3",
                            fontSize: "0.857rem",
                        },
                    },
                    axisTicks: {
                        show: false,
                    },
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "July",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                    axisBorder: {
                        show: false,
                    },
                    tickPlacement: "on",
                },
                yaxis: {
                    tickAmount: 5,
                    labels: {
                        style: {
                            colors: "#b9b9c3",
                            fontSize: "0.857rem",
                        },
                        formatter(val) {
                            return val > 999
                                ? `${(val / 1000).toFixed(1)}k`
                                : val;
                        },
                    },
                },
                tooltip: {
                    x: { show: false },
                },
            },
        });

        return { pacientesEntidad, salesLine };
    },
};
</script>
